import {FormattedMessage, useIntl} from "react-intl";
import {App, Button, Checkbox, DatePicker, Form, InputNumber, Modal, Select, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import api from "../../../../api/api";
import {csvImportExample} from "../../../../utils/csvImportExample";
import {InboxOutlined} from "@ant-design/icons";
import styles from "../../../InjectLeads/InjectLeadsForm/InjectForm.module.css";
import Dragger from "antd/es/upload/Dragger";

export const ImportTemplateModal = ({ isModalOpen, handleCancel, onSuccess }) => {
    const { formatMessage: f } = useIntl();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [selectedFile, setSelectedFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector((state) => state.globalSlice);

    const onImport = () => {
        const data = new FormData();
        data.append('file', selectedFile.fileList[0].originFileObj);
        setIsLoading(true);
        api
            .post('/template-integrations/import', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                setIsLoading(false);
                onSuccess(1)
            })
            .catch((e) => {
                setIsLoading(false);
                message.error(e.response.data?.message);
            });
    };
    if (!user) return '';

    return (
        <Modal
            title="Імпорт шаблонів"
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
        >
            <Form
                wrapperCol={{ span: 12, offset: 1 }}
                labelCol={{ span: 7, offset: 1 }}
                form={form}
                onFinish={onImport}
            >
                <Form.Item
                    name="file"
                    style={{ marginTop: 15 }}
                    wrapperCol={{ span: 24, offset: 1 }}
                    labelCol={{ span: 12, offset: 1 }}
                    rules={[
                        {
                            validator: (rule, value, callback, source, options) => {
                                if (!selectedFile) callback(f({id: 'select_file_validate'}));
                                return callback();
                            },
                        },
                    ]}
                >
                    <Dragger
                        name="file"
                        onChange={(e) => setSelectedFile(e)}
                        beforeUpload={() => false}
                        multiple={false}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            {f({id: 'click_upload_file'})}
                        </p>
                    </Dragger>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 10 }}>
                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                        {f({id: 'send'})}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};