import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { OfferForm } from '../OfferForm/OfferForm';
import {useIntl} from "react-intl";
import { useSelector } from 'react-redux';

export const OfferEdit = ({drawerId}) => {
  const { message } = App.useApp();
  const [offer, setOffer] = useState();
  const { formatMessage: f } = useIntl();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);
  const { features } = useSelector((state) => state.globalSlice);

  useEffect(() => {
    api.get(`/offer/${reqId}`).then((res) => {
      setOffer({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updateOffer) => {
    setIsDisabled(true);
    delete updateOffer.updatedAt;
    const data = new FormData();
    data.append('file', updateOffer?.file);
    data.append('lang', updateOffer?.lang);
    data.append('createdBy', updateOffer?.createdBy);
    data.append('description', updateOffer?.description);
    data.append('groupOfferId', updateOffer?.groupOfferId);
    data.append('isActive', updateOffer?.isActive);
    data.append('name', updateOffer?.name);
    data.append('user_id', updateOffer?.user_id);
    api
      .put(`/offer/${reqId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if(!drawerId){
          navigate(-1);
        }
        message.success(f({id: 'offer_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  if (!features.isOfferEnabled) {
    return;
  }

  return (
    <OfferForm isDisabled={isDisabled} offer={offer} onFinish={onFinish} />
  );
};
