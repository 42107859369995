import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  QRCode,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import api from '../../../../api/api';
import styles from '../../../../pages/Users/UsersForm/UsersForm.module.css';
import { SessionSection } from './SessionSection';
import {useDispatch, useSelector} from 'react-redux';
import { useIntl } from "react-intl";
import {QrModalOtp} from "./QrModalOtp";
import {setUser} from "../../../../store/sharedSlices/globalSlice";
import {LogoutOutlined} from "@ant-design/icons";
import {useHandleLogout} from "../../../../hooks/useHandleLogout";

export const ChangeProfileModal = ({ isModalOpen, handleCancel }) => {
  const [form] = Form.useForm();
  const { formatMessage: f } = useIntl();
  const [timezonesList, setTimezonesList] = useState([]);
  const [timezone, setTimezone] = useState();
  const [isOpenOtpModal, setIsOpenOtpModal] = useState(false);
  const [isEnabledOtp, setIsEnabledOtp] = useState(true);
  const [isOpenQrCodeInTelegram, setIsOpenQrCodeInTelegram] = useState(false);
  const [urlOtp, setUrlOtp] = useState('');
  const [isActive] = useState(false);
  const [userType, setUserType] = useState('')
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.globalSlice);
  const [handleLogout] = useHandleLogout();
  const [optionNotification, setOptionNotification] = useState([
    {
      value: 'deposit',
      label: f({ id: 'deposit' }),
    },
    {
      value: 'error-send',
      label: f({ id: 'error_send' }),
    },
    {
      value: 'reject',
      label: f({ id: 'reject_lead_status' }),
    },
    {
      value: 'inject-error-send',
      label: f({ id: 'inject_error_send' }),
    },
    {
      value: 'inject-reject',
      label: f({ id: 'inject_reject_send' }),
    },
    {

      value: 'was-new-but-now-rejected',
      label: f({id: 'wasNewButNowRejected'}),
    },
    {
      value: 'new-unsigned-lead',
      label: f({ id: 'new_unsigned_lead' }),
    },
  ]);
  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (user) {
      setUserType(user.type)
      form.setFieldsValue(user);
    }
    if (isModalOpen) {
      api.get(`/timezones`).then((res) => {
        setTimezonesList(res.data);
      });
    }

    if (
      user && user.isAdmin &&
      !optionNotification.filter((n) => n.value === 'status-update-failed')
        .length
    ) {
      const newOptions = [...optionNotification];
      newOptions.push({
        value: 'status-update-failed',
        label: f({ id: 'status_update_failed' }),
      },
      {
        value: 'new-timeout-lead',
        label: f({ id: 'new_timeout_lead' }),
      },);
      setOptionNotification(newOptions);
    }
  }, [user, isModalOpen]);
  if (!user) return '';

  const onUpdateUser = (data) => {
    api
      .put(`${userType}/update/${user.id}`, {
        timezone: data.timezone,
        telegram: data.telegram,
        notificationRules: JSON.stringify(data.notification),
        unsubscribe: isActive,
      })
      .then((_) => {
        window.location.reload();
        message.success(f({ id: 'update_user' }));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      });
  };
  const onUnSubscribeUser = () => {

    api
      .put(`${userType}/update/${user.id}`, {
        unsubscribe: true,
      })
      .then((_) => {
        message.success(f({ id: 'update_user' }));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      });
  };

  const onSetUpOTP = () => {
    api.put(`${userType}/set-up-otp/${user.id}`).then((r) => {
     setIsOpenOtpModal(true)
     setUrlOtp(r.data.url)
    }).catch((e) => {
      message.error(e.response.data?.message);
    })
  }

  const onDeleteOTP = () => {
    setIsOpenOtpModal(true)
    setIsEnabledOtp(false)
  }
  const handleCancelOtp = () => {
    api.get(`${userType}/${user.id}`).then((res) => {
      dispatch(setUser(res.data));
    }).finally(() => {
      setIsOpenOtpModal(false)
      setIsEnabledOtp(true)
    });
  }

  const encodeBackApi = () => {
    return btoa(`${window.REACT_APP_API_HOST}`)
  }

  const encodeTypeEntity = () => {
    return btoa(userType)
  }

  return (
      <>
      <QrModalOtp isModalOpen={isOpenOtpModal} url={urlOtp} handleCancel={handleCancelOtp} userId={user.id} enabled={isEnabledOtp} userType={userType} />
      <Modal
        zIndex={9}
        title={f({ id: 'change_profile' })}
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Button className="mt-5 mb-5" onClick={handleLogout} icon={ <LogoutOutlined />} type="primary" danger>
          {f({id: 'logout'})}
        </Button>
        {user.chatId && features.isTelegramBotEnabled && (
          <Form onFinish={onUnSubscribeUser}>
            <Form.Item wrapperCol={{ offset: 10 }}>
              <Button type="primary" htmlType="submit">
                {f({ id: 'unsubscribe_bot' })}
              </Button>
            </Form.Item>
          </Form>
        )}

        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={form}
          onFinish={onUpdateUser}
        >

          {!user.chatId && features.isTelegramBotEnabled && (
            <Form.Item label={f({ id: 'telegram_bot' })}>
              {isOpenQrCodeInTelegram ? (<QRCode
                  errorLevel="L"
                  value={`https://t.me/${window.REACT_APP_TELEGRAM_NAME}?start=${user.id}-=-${encodeTypeEntity()}-=-${user.clientId}`}
              />) : (<Button onClick={() => setIsOpenQrCodeInTelegram(!isOpenQrCodeInTelegram)} type="primary">
                {f({id: 'open_qr_code'})}
              </Button>)}
            </Form.Item>
          )}
          <Form.Item
            name="timezone"
            label={f({ id: 'timezone' })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={f({ id: 'timezone' })}
              style={{ maxWidth: 350 }}
              showSearch
              defaultValue={timezone}
              onChange={setTimezone}
              options={timezonesList.map((t) => ({
                value: t.timezone,
                label: t.name,
                key: t.timezone,
              }))}
            />
          </Form.Item>
          {user.chatId && features.isTelegramBotEnabled && (
            <Form.Item label={f({ id: 'notifications' })} name="notification">
              <Select
                mode="multiple"
                style={{ minWidth: 237 }}
                defaultValue={JSON.parse(user.notificationRules)}
                rootClassName={styles.input}
                options={optionNotification}
              />
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ offset: 7 }}>
            <Button style={{marginRight: '30px'}} type="primary" htmlType="submit">
              {f({id: 'save'})}
            </Button>
            {!user.otpEnabled ? ( <Button onClick={onSetUpOTP} type="primary">
              {f({id: 'add_2FA'})}
            </Button> ) : (<Button onClick={onDeleteOTP} type="primary" danger>
              {f({id: 'remove_2FA'})}
            </Button>) }
          </Form.Item>
        </Form>
        <Divider orientation="center">{f({id: 'sessions'})}</Divider>
        <SessionSection />
      </Modal>
      </>
  );
};
