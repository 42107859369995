import './App.css';
import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppLayout from './layout/Layout/AppLayout/AppLayout';
import { Login } from './pages/Login/Login';
import { AuthProvider } from './providers/AuthProvider';
import { App } from 'antd';
import { Autologin } from './pages/Autologin/Autologin';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { ThemeProvider } from './providers/ThemeProvider';
import { Provider, useSelector } from 'react-redux';
import { store } from './store/store';
import { CreateCallStatusCategory } from './pages/CallStatusCategories/CreateCallStatusCategory/CreateCallStatusCategory';
import { CallStatusCategoryChildren } from './pages/CallStatusCategories/CallStatusCategoryChildren/CallStatusCategoryChildren';
import { EditCallStatusCategory } from './pages/CallStatusCategories/EditCallStatusCategory/EditCallStatusCategory';
import { CallStatusCategories } from './pages/CallStatusCategories/CallStatusCategories/CallStatusCategories';
import {IntlProvider} from "react-intl";
import {LOCALES} from "./i18n/locales";
import { messages } from './i18n/messages'
import {TemplateIntegrations} from "./pages/TemplateIntegrations/TemplateIntegrations/TemplateIntegrations";
import {EditTemplateIntegration} from "./pages/TemplateIntegrations/EditTemplateIntegration/EditTemplateIntegration";
import {
    CreateTemplateIntegration
} from "./pages/TemplateIntegrations/CreateTemplateIntegration/CreateTemplateIntegration";
import ErrorBoundary from "./pages/Errors/Error";
import ErrorPage from "./pages/Errors/ErrorPage";
import {DirectionEdit} from "./pages/Direction/DirectionEdit/DirectionEdit";
const Users = lazy(() =>
  import('./pages/Users/Users/Users').then((module) => ({
    default: module.Users,
  }))
);
const Orders = lazy(() =>
  import('./pages/Orders/Orders/Orders').then((module) => ({
    default: module.Orders,
  }))
);
const Boxes = lazy(() =>
  import('./pages/Boxes/Boxes/Boxes').then((module) => ({
    default: module.Boxes,
  }))
);
const Integrations = lazy(() =>
  import('./pages/Integrations/Integrations/Integrations').then((module) => ({
    default: module.Integrations,
  }))
);
const Teams = lazy(() =>
  import('./pages/Teams/Teams/Teams').then((module) => ({
    default: module.Teams,
  }))
);
const LeadDetails = lazy(() =>
  import('./pages/Leads/LeadDetails/LeadDetails').then((module) => ({
    default: module.LeadDetails,
  }))
);
const CreateUser = lazy(() =>
  import('./pages/Users/CreateUser/CreateUser').then((module) => ({
    default: module.CreateUser,
  }))
);
const EditUser = lazy(() =>
  import('./pages/Users/EditUser/EditUser').then((module) => ({
    default: module.EditUser,
  }))
);
const NoPermissions = lazy(() =>
  import('./pages/NoPermissions/NoPermissions').then((module) => ({
    default: module.NoPermissions,
  }))
);
const Lids = lazy(() =>
  import('./pages/Leads/Leads/Leads').then((module) => ({
    default: module.Lids,
  }))
);
const EditOrder = lazy(() =>
  import('./pages/Orders/EditOrder/EditOrder').then((module) => ({
    default: module.EditOrder,
  }))
);
const CreateOrder = lazy(() =>
  import('./pages/Orders/CreateOrder/CreateOrder').then((module) => ({
    default: module.CreateOrder,
  }))
);
const CreateBox = lazy(() =>
  import('./pages/Boxes/CreateBox/CreateBox').then((module) => ({
    default: module.CreateBox,
  }))
);
const EditBox = lazy(() =>
  import('./pages/Boxes/EditBox/EditBox').then((module) => ({
    default: module.EditBox,
  }))
);
const CreateIntegration = lazy(() =>
  import('./pages/Integrations/CreateIntegration/CreateIntegration').then(
    (module) => ({ default: module.CreateIntegration })
  )
);
const EditIntegration = lazy(() =>
  import('./pages/Integrations/EditIntegration/EditIntegration').then(
    (module) => ({ default: module.EditIntegration })
  )
);
const EditTeam = lazy(() =>
  import('./pages/Teams/EditTeam/EditTeam').then((module) => ({
    default: module.EditTeam,
  }))
);
const CreateTeam = lazy(() =>
  import('./pages/Teams/CreateTeam/CreateTeam').then((module) => ({
    default: module.CreateTeam,
  }))
);
const Customers = lazy(() =>
  import('./pages/Customer/Customers/Customers').then((module) => ({
    default: module.Customers,
  }))
);
const EditCustomer = lazy(() =>
  import('./pages/Customer/EditCustomer/EditCustomer').then((module) => ({
    default: module.EditCustomer,
  }))
);
const CreateCustomer = lazy(() =>
  import('./pages/Customer/CreateCustomer/CreateCustomer').then((module) => ({
    default: module.CreateCustomer,
  }))
);
const Kpi = lazy(() =>
  import('./pages/Kpi/Kpi/Kpi').then((module) => ({
    default: module.Kpi,
  }))
);
const EditKpi = lazy(() =>
  import('./pages/Kpi/EditKpi/EditKpi').then((module) => ({
    default: module.EditKpi,
  }))
);
const CreateKpi = lazy(() =>
  import('./pages/Kpi/CreateKpi/CreateKpi').then((module) => ({
    default: module.CreateKpi,
  }))
);
const KpiReport = lazy(() =>
  import('./pages/KpiReport/KpiReport').then((module) => ({
    default: module.KpiReport,
  }))
);
const Logs = lazy(() =>
  import('./pages/Logs/Logs').then((module) => ({ default: module.Logs }))
);
const CallStatusGroups = lazy(() =>
  import('./pages/CallStatusGroups/CallStatusGroups/CallStatusGroups').then(
    (module) => ({ default: module.CallStatusGroups })
  )
);
const CreateCallStatusGroup = lazy(() =>
  import(
    './pages/CallStatusGroups/CreateCallStatusGroup/CreateCallStatusGroup'
  ).then((module) => ({ default: module.CreateCallStatusGroup }))
);
const EditCallStatusGroups = lazy(() =>
  import(
    './pages/CallStatusGroups/EditCallStatusGroups/EditCallStatusGroups'
  ).then((module) => ({ default: module.EditCallStatusGroups }))
);
const InjectForm = lazy(() =>
  import('./pages/InjectLeads/InjectLeadsForm/InjectForm').then((module) => ({
    default: module.InjectForm,
  }))
);
const Domains = lazy(() =>
  import('./pages/Domains/Domains/Domains').then((module) => ({
    default: module.Domains,
  }))
);
const DomainsPurchase = lazy(() =>
  import('./pages/DomainsPurchase/DomainsPurchase').then((module) => ({
    default: module.DomainsPurchase,
  }))
);
const Cloudflare = lazy(() =>
  import('./pages/Cloudflare/Cloudflare/Cloudflare').then((module) => ({
    default: module.Cloudflare,
  }))
);
const EditCloudflare = lazy(() =>
  import('./pages/Cloudflare/EditCloudflare/EditCloudflare').then((module) => ({
    default: module.EditCloudflare,
  }))
);
const CreateCloudflare = lazy(() =>
  import('./pages/Cloudflare/CreateCloudflare/CreateCloudflare').then(
    (module) => ({ default: module.CreateCloudflare })
  )
);
const Servers = lazy(() =>
  import('./pages/Servers/Servers/Servers').then((module) => ({
    default: module.Servers,
  }))
);
const EditServers = lazy(() =>
  import('./pages/Servers/EditServers/EditServers').then((module) => ({
    default: module.EditServers,
  }))
);
const CreateServer = lazy(() =>
  import('./pages/Servers/CreateServer/CreateServer').then((module) => ({
    default: module.CreateServer,
  }))
);
const Regway = lazy(() =>
  import('./pages/Regway/Regway/Regway').then((module) => ({
    default: module.Regway,
  }))
);
const EditRegway = lazy(() =>
  import('./pages/Regway/EditServers/EditRegway').then((module) => ({
    default: module.EditRegway,
  }))
);
const CreateRegway = lazy(() =>
  import('./pages/Regway/CreateRegway/CreateRegway').then((module) => ({
    default: module.CreateRegway,
  }))
);
const Report = lazy(() =>
  import('./pages/Report/Report').then((module) => ({ default: module.Report }))
);
const CountryBox = lazy(() =>
  import('./pages/CountryBox/CountryBox/CountryBox').then((module) => ({
    default: module.CountryBox,
  }))
);
const CreateCountryBox = lazy(() =>
  import('./pages/CountryBox/CreateCountryBox/CreateCountryBox').then(
    (module) => ({ default: module.CreateCountryBox })
  )
);
const EditCountryBox = lazy(() =>
  import('./pages/CountryBox/EditCloudflare/EditCountryBox').then((module) => ({
    default: module.EditCountryBox,
  }))
);
const EntityLogs = lazy(() =>
  import('./pages/EntityLogs/EntityLogs').then((module) => ({
    default: module.EntityLogs,
  }))
);
const Campains = lazy(() =>
  import('./pages/Campaign/Campains/Campains').then((module) => ({
    default: module.Campains,
  }))
);
const EditCampaign = lazy(() =>
  import('./pages/Campaign/EditCampaign/EditCampaign').then((module) => ({
    default: module.EditCampaign,
  }))
);
const CreateCampaign = lazy(() =>
  import('./pages/Campaign/CreateCampaign/CreateCampaign').then((module) => ({
    default: module.CreateCampaign,
  }))
);
const Permissions = lazy(() =>
  import('./pages/Permissions/Permissions/Permissions').then((module) => ({
    default: module.Permissions,
  }))
);
const EditPermissions = lazy(() =>
  import('./pages/Permissions/EditPermissions/EditPermissions').then(
    (module) => ({ default: module.EditPermissions })
  )
);
const CreatePermissions = lazy(() =>
  import('./pages/Permissions/CreatePermissions/CreatePermissions').then(
    (module) => ({ default: module.CreatePermissions })
  )
);
const DuplicatePermissions = lazy(() =>
  import('./pages/Permissions/DuplicatePermissions/DuplicatePermissions').then(
    (module) => ({ default: module.DuplicatePermissions })
  )
);
const BuyerPermissions = lazy(() =>
    import('./pages/BuyerPermissions/Permissions/BuyerPermissions').then((module) => ({
        default: module.BuyerPermissions,
    }))
);
const EditBuyerPermissions = lazy(() =>
    import('./pages/BuyerPermissions/EditPermissions/EditBuyerPermissions').then(
        (module) => ({ default: module.EditBuyerPermissions })
    )
);
const CreateBuyerPermissions = lazy(() =>
    import('./pages/BuyerPermissions/CreatePermissions/CreateBuyerPermissions').then(
        (module) => ({ default: module.CreateBuyerPermissions })
    )
);
const DuplicateBuyerPermissions = lazy(() =>
    import('./pages/BuyerPermissions/DuplicatePermissions/DuplicateBuyerPermissions').then(
        (module) => ({ default: module.DuplicateBuyerPermissions })
    )
);
const Groups = lazy(() =>
  import('./pages/Group/Groups/Groups').then((module) => ({
    default: module.Groups,
  }))
);
const CreateGroup = lazy(() =>
  import('./pages/Group/CreateGroup/CreateGroup').then((module) => ({
    default: module.CreateGroup,
  }))
);
const EditGroup = lazy(() =>
  import('./pages/Group/GroupEdit/GroupEdit').then((module) => ({
    default: module.EditGroup,
  }))
);
const Offers = lazy(() =>
  import('./pages/Offer/Offers/Offers').then((module) => ({
    default: module.Offers,
  }))
);
const CreateOffer = lazy(() =>
  import('./pages/Offer/CreateOffer/CreateOffer').then((module) => ({
    default: module.CreateOffer,
  }))
);
const OfferEdit = lazy(() =>
  import('./pages/Offer/OfferEdit/OfferEdit').then((module) => ({
    default: module.OfferEdit,
  }))
);
const Tasks = lazy(() =>
  import('./pages/Task/Tasks/Tasks').then((module) => ({
    default: module.Tasks,
  }))
);
const Reports = lazy(() =>
  import('./pages/Report/fakeReports/Report').then((module) => ({
    default: module.Reports,
  }))
);

const DesignReports = lazy(() =>
    import('./pages/Report/designReport/Report').then((module) => ({
        default: module.Reports,
    }))
);
const Brands = lazy(() =>
  import('./pages/Brands/Brands/Brands').then((module) => ({
    default: module.Brands,
  }))
);
const CreateBrand = lazy(() =>
  import('./pages/Brands/CreateBrand/CreateBrand').then((module) => ({
    default: module.CreateBrand,
  }))
);
const EditBrand = lazy(() =>
  import('./pages/Brands/EditBrand/EditBrand').then((module) => ({
    default: module.EditBrand,
  }))
);
const Dashboard = lazy(() =>
  import('./pages/Dashboard/Dashboard').then((module) => ({
    default: module.Dashboard,
  }))
);
const Buyers = lazy(() =>
  import('./pages/Buyers/Buyers/Buyers').then((module) => ({
    default: module.Buyers,
  }))
);
const CreateBuyers = lazy(() =>
  import('./pages/Buyers/CreateBuyer/CreateBuyer').then((module) => ({
    default: module.CreateBuyer,
  }))
);
const EditBuyers = lazy(() =>
  import('./pages/Buyers/EditBuyer/EditBuyer').then((module) => ({
    default: module.EditBuyer,
  }))
);
const Blacklists = lazy(() =>
    import('./pages/BlackList/Blacklists/Blacklists').then((module) => ({
      default: module.Blacklists,
    }))
);
const CreateBlacklist = lazy(() =>
    import('./pages/BlackList/CreateBlacklist/CreateBlacklist').then((module) => ({
        default: module.CreateBlacklist,
    }))
);
const EditBlacklists = lazy(() =>
    import('./pages/BlackList/EditBlacklist/EditBlacklist').then((module) => ({
        default: module.EditBlacklist,
    }))
);

const Namecheap = lazy(() =>
    import('./pages/Namecheap/Namecheap/Namecheap').then((module) => ({
      default: module.Namecheap,
    }))
);

const EditNamecheap = lazy(() =>
    import('./pages/Namecheap/EditNamecheaps/EditNamecheap').then((module) => ({
        default: module.EditNamecheap,
    }))
);

const CreateNamecheap = lazy(() =>
    import('./pages/Namecheap/CreateNamecheap/CreateNamecheap').then((module) => ({
        default: module.CreateNamecheap,
    }))
);

const DefaultIntegrations = lazy(() =>
    import('./pages/TemplateIntegrations/TemplateIntegrations/TemplateIntegrations').then((module) => ({
        default: module.TemplateIntegrations,
    }))
);

const EditDefaultIntegrations = lazy(() =>
    import('./pages/TemplateIntegrations/EditTemplateIntegration/EditTemplateIntegration').then((module) => ({
        default: module.EditTemplateIntegration,
    }))
);

const CreateDefaultIntegrations = lazy(() =>
    import('./pages/TemplateIntegrations/CreateTemplateIntegration/CreateTemplateIntegration').then((module) => ({
        default: module.CreateTemplateIntegration,
    }))
);

const CrmSetting = lazy(() =>
    import('./pages/CrmSetting/CrmSetting').then((module) => ({
        default: module.CrmSetting,
    }))
);

const CallStatusAnswer = lazy(() =>
    import('./pages/CallStatusAnswer/CallStatusAnswer').then((module) => ({
        default: module.CallStatusAnswer,
    }))
);

const Directions = lazy(() =>
    import('./pages/Direction/Directions/Directions').then((module) => ({
        default: module.Directions,
    }))
);

const EditDirections = lazy(() =>
    import('./pages/Direction/DirectionEdit/DirectionEdit').then((module) => ({
        default: module.DirectionEdit,
    }))
);

const CreateDirections = lazy(() =>
    import('./pages/Direction/CreateDirection/CreateDirection').then((module) => ({
        default: module.CreateDirection,
    }))
);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
        <ErrorBoundary>
          <AuthProvider>
            <ThemeProvider>
              <AppLayout />
            </ThemeProvider>
          </AuthProvider>
        </ErrorBoundary>
    ),
    children: [
      {
        path: 'leads',
        element: <Lids />,
      },
      {
        path: 'leads/:id',
        element: <LeadDetails />,
      },
      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: 'orders/:id',
        element: <EditOrder />,
      },
      {
        path: 'orders/create',
        element: <CreateOrder />,
      },
      {
        path: 'boxes',
        element: <Boxes />,
      },
      {
        path: 'boxes/:id',
        element: <EditBox />,
      },
      {
        path: 'boxes/create',
        element: <CreateBox />,
      },
      {
        path: 'integrations',
        element: <Integrations />,
      },
      {
        path: 'integrations/create',
        element: <CreateIntegration />,
      },
      {
        path: 'integrations/:id',
        element: <EditIntegration />,
      },
      {
        path: 'customers',
        element: <Customers />,
      },
      {
        path: 'customers/:id',
        element: <EditCustomer />,
      },
      {
        path: 'customers/create',
        element: <CreateCustomer />,
      },
      {
        path: 'call-status-groups',
        element: <CallStatusGroups />,
      },
      {
        path: 'call-status-groups/create',
        element: <CreateCallStatusGroup />,
      },
      {
        path: 'call-status-groups/:id',
        element: <EditCallStatusGroups />,
      },
      {
        path: 'country-box',
        element: <CountryBox />,
      },
      {
        path: 'country-box/create',
        element: <CreateCountryBox />,
      },
      {
        path: 'country-box/:id',
        element: <EditCountryBox />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:id',
        element: <EditUser />,
      },
      {
        path: 'users/create',
        element: <CreateUser />,
      },
      {
        path: 'permissions',
        element: <Permissions />,
      },
      {
        path: 'permissions/:id',
        element: <EditPermissions />,
      },
      {
        path: 'permissions/create',
        element: <CreatePermissions />,
      },
      {
        path: 'permissions/duplicate/:id',
        element: <DuplicatePermissions />,
      },
      {
        path: 'buyer-permissions',
        element: <BuyerPermissions />,
      },
      {
        path: 'buyer-permissions/:id',
        element: <EditBuyerPermissions />,
      },
      {
        path: 'buyer-permissions/create',
        element: <CreateBuyerPermissions />,
      },
      {
        path: 'buyer-permissions/duplicate/:id',
        element: <DuplicateBuyerPermissions />,
      },
      {
        path: 'teams',
        element: <Teams />,
      },
      {
        path: 'teams/:id',
        element: <EditTeam />,
      },
      {
        path: 'teams/create',
        element: <CreateTeam />,
      },
      {
        path: 'logs',
        element: <Logs />,
      },
      {
        path: 'inject-form',
        element: <InjectForm />,
      },
      {
        path: 'no-permissions',
        element: <NoPermissions />,
      },
      {
        path: 'domains',
        element: <Domains />,
      },
      {
        path: 'cloudflare',
        element: <Cloudflare />,
      },
      {
        path: 'cloudflare/:id',
        element: <EditCloudflare />,
      },
      {
        path: 'cloudflare/create',
        element: <CreateCloudflare />,
      },
      {
        path: 'servers',
        element: <Servers />,
      },
      {
        path: 'servers/:id',
        element: <EditServers />,
      },
      {
        path: 'servers/create',
        element: <CreateServer />,
      },
      {
        path: 'regway',
        element: <Regway />,
      },
      {
        path: 'regway/:id',
        element: <EditRegway />,
      },
      {
        path: 'regway/create',
        element: <CreateRegway />,
      },
      {
        path: 'buy',
        element: <DomainsPurchase />,
      },
      {
        path: 'entity-logs',
        element: <EntityLogs />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'designer-reports',
        element: <DesignReports />,
      },
      {
          path: 'design-reports',
          element: <DesignReports />,
      },
      {
        path: 'campaign',
        element: <Campains />,
      },
      {
        path: 'campaign/:id',
        element: <EditCampaign />,
      },
      {
        path: 'campaign/create',
        element: <CreateCampaign />,
      },
      {
        path: 'groups-offer',
        element: <Groups />,
      },
      {
        path: 'groups-offer/create',
        element: <CreateGroup />,
      },
      {
        path: 'groups-offer/:id',
        element: <EditGroup />,
      },
      {
        path: 'offers',
        element: <Offers />,
      },
      {
        path: 'offer/create',
        element: <CreateOffer />,
      },
      {
        path: 'offer/:id',
        element: <OfferEdit />,
      },
      {
        path: 'kpi-groups',
        element: <Kpi />,
      },
      {
        path: 'kpi-report',
        element: <KpiReport />,
      },
      {
        path: 'kpi-groups/:id',
        element: <EditKpi />,
      },
      {
        path: 'kpi-groups/create',
        element: <CreateKpi />,
      },
      {
        path: 'brands',
        element: <Brands />,
      },
      {
        path: 'brand/create',
        element: <CreateBrand />,
      },
      {
        path: 'brands/:id',
        element: <EditBrand />,
      },
      {
        path: 'tasks',
        element: <Tasks />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'webmasters',
        element: <Buyers />,
      },
      {
        path: 'webmasters/create',
        element: <CreateBuyers />,
      },
      {
        path: 'webmasters/:id',
        element: <EditBuyers />,
      },
      {
        path: 'call-status-categories',
        element: <CallStatusCategories />,
      },
      {
        path: 'call-status-categories/create',
        element: <CreateCallStatusCategory />,
      },
      {
        path: 'call-status-categories/:id',
        element: <EditCallStatusCategory />,
      },
      {
        path: 'call-status-categories/children/:parentId',
        element: <CallStatusCategoryChildren />,
      },
      {
        path: 'call-status-categories/children/create/:parentId',
        element: <CreateCallStatusCategory />,
      },
      {
        path: 'blacklists',
        element: <Blacklists />,
      },
      {
        path: 'blacklists/create',
        element: <CreateBlacklist />,
      },
      {
        path: 'blacklists/:id',
        element: <EditBlacklists />,
      },
      {
        path: 'namecheap',
        element: <Namecheap />,
      },
      {
        path: 'namecheap/:id',
        element: <EditNamecheap />,
      },
      {
        path: 'namecheap/create',
        element: <CreateNamecheap />,
      },
      {
        path: 'crm-setting',
        element: <CrmSetting />,
      },
      {
          path: 'template-integrations',
          element: <TemplateIntegrations />,
      },
      {
          path: 'template-integrations/:id',
          element: <EditTemplateIntegration />,
      },
      {
          path: 'template-integrations/create',
          element: <CreateTemplateIntegration />,
      },
        {
            path: 'call-status-answer',
            element: <CallStatusAnswer />,
        },
        {
            path: 'directions',
            element: <Directions />,
        },
        {
            path: 'directions/create',
            element: <CreateDirections />,
        },
        {
            path: 'directions/:id',
            element: <EditDirections />,
        },
      {
          path: '*',
          element: <ErrorPage code="404" description="Sorry, the page you visited does not exist." />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/autologin/:id',
    element: <Autologin />,
  },
]);
const locale = LOCALES[localStorage.getItem('locale') || 'UA']
function HellLeadsCrm() {
  return (
        <Provider store={store}>
            <IntlProvider messages={messages[locale.locale]}
                          locale={locale.locale}
                          defaultLocale={LOCALES.GB.locale}>
              <App>
                <RouterProvider router={router} />
              </App>
            </IntlProvider>
        </Provider>
  );
}

export default HellLeadsCrm;
